import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'

export const LabelAndText = ({ label, text, fontSize = 14, labelWidth = 120, divider = false, color = 'default', ignoreNewLine = true }) => (
    <React.Fragment>
        <Stack direction='row' sx={{ color: color }}>
            <Typography color='inherit' noWrap fontWeight={'bold'} fontSize={fontSize} sx={{ width: labelWidth }} >{label}</Typography>
            <Typography color='inherit' fontSize={fontSize} noWrap sx={{ whiteSpace: ignoreNewLine ? 'nowrap' : 'pre-line'}}>{text}</Typography>
        </Stack>
        {divider && <Divider />}
    </React.Fragment>
)

export const LabelAndTextStacked = ({ label, labelWidth = 120, text }) => (
    <React.Fragment>
        <Stack direction={'row'} spacing={2}>
            <Typography sx={{ width: labelWidth }} fontSize={12} fontWeight='regular'>{label}:</Typography>
            <Typography fontSize={14} fontWeight='bold'>{`${text}`}</Typography>
        </Stack>
        <Divider />
    </React.Fragment>
)



export const LabelAndTextCopy = ({ label, text, labelWidth = 150, direction = 'row', divider = true, color = 'default', fontSize = 12, canCopy = true }) => {

    const [bgColor, setBgColor] = useState('transparent')
    const handleCopy = () => {
        if (canCopy) {
            navigator.clipboard.writeText(text)
            setBgColor('primary.light')
            setTimeout(() => {
                setBgColor('transparent')
            }, 200);
        }
    }
    return (
        <Box onClick={handleCopy} sx={{
            cursor: canCopy ? 'pointer' : 'default',
            backgroundColor: bgColor,
        }} >
            <LabelAndText label={label} text={text} divider={divider} labelWidth={labelWidth} />
        </Box>
    )
}
